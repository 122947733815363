<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="기본조건">
        <v-col :class="CLASS1">
          <v-col cols="3">조회기간</v-col>
          <v-col cols="9">
            <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
            />
          </v-col>
          <v-col cols="4">회원명</v-col>
            <v-col cols="5">
              <v-text-field
                  v-model="inviterNm"
                  outlined dense hide-details
                  background-color="white" menu-props="auto"
              />
          </v-col>
          <v-col cols="4">고객명</v-col>
            <v-col cols="5">
              <v-text-field
                  v-model="customerNm"
                  outlined dense hide-details
                  background-color="white" menu-props="auto"
              />
          </v-col>
          <v-col cols="4">차량번호</v-col>
            <v-col cols="5">
              <v-text-field
                  v-model="carNo"
                  outlined dense hide-details
                  background-color="white" menu-props="auto"
              />
          </v-col>
        </v-col>
        <v-col cols="2" align-self="center" offset="11">
          <v-btn @click="toggleShow" style="margin-right: 10px;">
          상세조건
          </v-btn> 
        </v-col>
      </search-area>
      <v-divider v-if="show"/>
      <v-divider v-if="show"/>
      <search-area title="약정정보" v-if="show">
        <v-col v-for="(item, index) in basicAgreements" :key="index" cols="1">
          <v-select
            v-model="$data[item.code]"
            :items="item.items"
            item-text="text"
            item-value="value"
            :label="item.label"
            return-object
            @keypress.enter="getCalcList"
          ></v-select> 
        </v-col>
      </search-area>
      <v-divider v-if="show"/>
      <search-area title="특별할인1" v-if="show">
       <v-col v-for="(item, index) in specialDc1s" :key="index" cols="1"> 
          <v-select
            v-model="$data[item.code]"
            :items="item.items"
            item-text="text"
            item-value="value"
            :label="item.label"
            return-object
            @keypress.enter="getCalcList"
          >
          </v-select> 
        </v-col>
      </search-area>
      <v-divider v-if="show"/>
      <search-area title="특별할인2" v-if="show">
        <v-col v-for="(item, index) in specialDc2s" :key="index" cols="1"> 
          <v-select
            v-model="$data[item.code]"
            :items="item.items"
            item-text="text"
            item-value="value"
            :label="item.label"
            return-object
            @keypress.enter="getCalcList"
          ></v-select> 
        </v-col>
      </search-area>


    </template>
    <template v-slot:button>
      <search-btn @click="getCalcList"/>
    </template>
  </search-bar-layout>
</template>

<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";

export default {
  mixins: [SearchBarMixin, DateMixin],
  props: ["name"],
  components: {DateInput, SearchArea, SearchBarLayout},
  created() {
    if (this.name) {
      this.inviterNm = this.name;
    }
  },
  beforeMount() {
    const date = new Date();
    date.setMonth(date.getMonth());
    const [year, month] = [date.getFullYear(), date.getMonth()];
    this.startDate = `${year}-${this.addZero(month + 1)}-01`;
    this.endDate = `${year}-${this.addZero(month + 1)}-${new Date(year, month + 1, 0).getDate()}`;
    if (this.inviterNm) {
      this.getCalcList();
    }
  },
  data: () => ({
    startDate: "",
    endDate: "",
    customerNm: "",
    inviterNm: "",
    carNo: "",
    show: false,
    basicAgreement1: "",
    basicAgreement2: "",
    basicAgreement3: "",
    basicAgreement4: "",
    basicAgreement5: "",
    basicAgreement6: "",
    basicAgreement7: "",
    specialDc1: "",
    specialDc2: "",
    specialDc3: "",
    specialDc4: "",
    specialDc7: "",
    specialDc8: "",
    specialDc9: "",
    specialDc10: "",
    specialDc11: "",
    specialDc12: "",
    specialDc13: "",

    basicAgreements :[
    {
      code : "basicAgreement1",
      label : "대인배상2",
      items :[{text:"선택", value: ""},{text: "미가입", value: "0"},{text: "가입(무한)", value: "1"}]
    },

    {
      code : "basicAgreement2",
      label : "대물배상",
      items :[{text:"선택", value: ""},{text: "2천", value: "1"},{text: "3천", value: "2"},{text: "5천", value: "3"},{text: "1억", value: "4"},{text: "2억", value: "5"},{text: "3억", value: "6"},{text: "5억", value: "7"}]
    },
    {
      code : "basicAgreement3",
      label: "자기신체손해",
      items: [{text:"선택", value: ""},{text: "미가입", value: "0"},{text: "자기신체사고", value: "1"},{text: "자동차상해", value: "2"}]
    },
    {
      code : "basicAgreement4",
      label: "무보험상해",
      items: [{text:"선택", value: ""},{text: "미가입", value: "0"},{text: "가입(2억)", value: "1"}]
    },
    {
      code : "basicAgreement5",
      label: "긴급출동서비스",
      items: [{text:"선택", value: ""},{text: "미가입", value: "0"},{text: "가입", value: "1"}]
    },
    {
      code : "basicAgreement6",
      label: "자기차량손해",
      items: [{text:"선택", value: ""},{text: "미가입", value: "0"},{text: "가입", value: "1"}]
    },
    {
      code : "basicAgreement7",
      label: "물적사고할증기준금액",
      items: [{text:"선택", value: ""},{text: "50만", value: "1"},{text: "100만", value: "2"},{text: "150만", value: "3"},{text: "200만", value: "4"}]
    },
    ],
    specialDc1s: [
      {
        code : "specialDc1",
        label: "마일리지할인",
        items: [{text:"선택", value: ""},{text: "미적용", value: "0"},{text: "적용", value: "1"}]
      },
      {
        code : "specialDc2",
        label: "Email할인",
        items: [{text:"선택", value: ""},{text: "미적용", value: "0"},{text: "적용", value: "1"}]
      },
      {
        code : "specialDc3",
        label: "자녀할인",
        items: [{text:"선택", value: ""},{text: "미적용", value: "0"},{text: "자녀", value: "1"}, {text: "태아", value: "2"}]
      },
      {
        code : "specialDc4",
        label: "대중교통할인",
        items: [{text:"선택", value: ""},{text: "미적용", value: "0"},{text: "6또는12만이상", value: "1"}, {text: "12또는24만원이상", value: "2"}]
      },
      {
        code : "specialDc7",
        label: "과거주행거리연동",
        items: [{text:"선택", value: ""},{text: "미적용", value: "0"},{text: "적용", value: "1"}]
      },
    ],
    specialDc2s: [
      {
        code : "specialDc8",
        label: "커넥티드할인",
        items: [{text:"선택", value: ""},{text: "미적용", value: "0"},{text: "적용", value: "1"}]
      },
      {
        code : "specialDc9",
        label: "서민우대",
        items: [{text:"선택", value: ""},{text: "미적용", value: "0"},{text: "적용", value: "1"}]
      },
      {
        code : "specialDc10",
        label: "안전운전점수",
        items: [{text:"선택", value: ""},{text: "미적용", value: "0"},{text: "적용", value: "1"}]
      },
      {
        code : "specialDc11",
        label: "현대안전운전습관",
        items: [{text:"선택", value: ""},{text: "미적용", value: "0"},{text: "적용", value: "1"}]
      },
      {
        code : "specialDc12",
        label: "차선이탈경고장치",
        items: [{text:"선택", value: ""},{text: "미적용", value: "0"},{text: "적용", value: "1"}]
      },
      {
        code : "specialDc13",
        label: "전방충돌방지장치",
        items: [{text:"선택", value: ""},{text: "미적용", value: "0"},{text: "적용", value: "1"}]
      },
    ]
  }),
  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    toggleShow(){
      this.show = !this.show;
    },
    getCalcList() {
      // if (!this.isEnter) return;
      const params = {
        "carInfoFilter": {},
        "startDate": this.startDate.replaceAll('-', ''),
        "endDate": this.endDate.replaceAll('-', '')
      }
      if (this.customerNm!=="" && this.customerNm.trim()!= "") params['customerNm'] = this.customerNm.trim();
      if (this.inviterNm!=="" && this.inviterNm.trim()!= "") params['inviterNm'] = this.inviterNm.trim();
      if (this.carNo!=="" && this.carNo.trim()!= "") params["carInfoFilter"]['carNo'] = this.carNo.trim();
      if (this.basicAgreement1!=="") params["carInfoFilter"]['basicAgreement1'] = this.basicAgreement1.value;
      if (this.basicAgreement2!=="") params["carInfoFilter"]['basicAgreement2'] = this.basicAgreement2.value;
      if (this.basicAgreement3!=="") params["carInfoFilter"]['basicAgreement3'] = this.basicAgreement3.value;
      if (this.basicAgreement4!=="") params["carInfoFilter"]['basicAgreement4'] = this.basicAgreement4.value;
      if (this.basicAgreement5!=="") params["carInfoFilter"]['basicAgreement5'] = this.basicAgreement5.value;
      if (this.basicAgreement6!=="") params["carInfoFilter"]['basicAgreement6'] = this.basicAgreement6.value;
      if (this.basicAgreement7!=="") params["carInfoFilter"]['basicAgreement7'] = this.basicAgreement7.value;
      if (this.specialDc1!=="") params["carInfoFilter"]['specialDc1'] = this.specialDc1.value;
      if (this.specialDc2!=="") params["carInfoFilter"]['specialDc2'] = this.specialDc2.value;
      if (this.specialDc3!=="") params["carInfoFilter"]['specialDc3'] = this.specialDc3.value;
      if (this.specialDc4!=="") params["carInfoFilter"]['specialDc4'] = this.specialDc4.value;
      if (this.specialDc7!=="") params["carInfoFilter"]['specialDc7'] = this.specialDc7.value;
      if (this.specialDc8!=="") params["carInfoFilter"]['specialDc8'] = this.specialDc8.value;
      if (this.specialDc9!=="") params["carInfoFilter"]['specialDc9'] = this.specialDc9.value;
      if (this.specialDc10!=="") params["carInfoFilter"]['specialDc10'] = this.specialDc10.value;
      if (this.specialDc11!=="") params["carInfoFilter"]['specialDc11'] = this.specialDc11.value;
      if (this.specialDc12!=="") params["carInfoFilter"]['specialDc12'] = this.specialDc12.value;
      if (this.specialDc13!=="") params["carInfoFilter"]['specialDc12'] = this.specialDc13.value;
      console.log("params", params);

      this.$emit('search', params);

    },
  }
}
</script>

<style scoped>

</style>