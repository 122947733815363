<template>
  <v-col cols="12" class="py-0">
    <span class="text-h6">목록 (총 {{ data.length }}개)</span>
    <v-flex xs12 sm12 md12 lg12 xl12>
      <v-data-table
          height="50vh"
          :items="data"
          :loading="loading"
          :items-per-page="-1"
          fixed-header disable-sort calculate-widths
          :headers="getHeader(SIGN_UP_REQUEST_TABLE)"
          :footer-props="{'items-per-page-options': [10, 30, 50, -1]}"
      >
        <template v-slot:item.businessCardImgStr="{ item }">
          <v-btn color="red lighten-5" class="px-1 salseCard"
                 @click="openModal(item)"
          >명함보기
          </v-btn>
        </template>

        <template v-slot:item.ssno="{ value }">
          {{ value ? `${value.slice(0, 6)}-${value.slice(6, 13)}` : '' }}
        </template>

        <template v-slot:item.telNum="{ value }">
          {{ value ? `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}` : '' }}
        </template>

        <template v-slot:item.createDate="{ value }">
          <span v-if="value">{{ new Date(value).toLocaleString() }}</span>
        </template>

        <template v-slot:item.approvalDate="{ value }">
          <span v-if="value">{{ new Date(value).toLocaleString() }}</span>
        </template>

        <template v-slot:item.approvalYn="{ item }">
          <p v-if="item.approvalYn === 'Y'"
             style="justify-content: center; align-items: center; margin: 0;">승인완료</p>
          <p v-if="item.approvalYn === 'X'"
             style="justify-content: center; align-items: center; margin: 0;">승인거절</p>
          <v-btn v-if="item.approvalYn == 'N'"
                 color="green lighten-5" class="px-1 salseCard"
                 @click="approveUser(item, 'Y')">승인
          </v-btn>
          <v-btn v-if="item.approvalYn == 'N'"
                 color="red lighten-5" class="px-1 salseCard"
                 @click="approveUser(item, 'X')">거절
          </v-btn>
        </template>

      </v-data-table>
    </v-flex>

    <!-- 명함 이미지 확인 팝업 -->
    <sign-up-request-biz-card-modal
        :key="modalKey"
        :is-show="isShow"
        :selectedUser="selectedUser"
        @close="isShow = false"
    />
  </v-col>
</template>

<script>
import TableMixin from "@/mixin/TableMixin";
import {axiosInstance} from "@/util/AxiosModule";
import {SIGN_UP_REQUEST_TABLE} from "@/util/TableHeaders";
import SignUpRequestBizCardModal from "./SignUpRequestBizCardModal";

export default {
  components: {SignUpRequestBizCardModal},
  mixins: [TableMixin],
  props: ['data', 'loading'],

  data: () => ({
    isShow: false,
    selectedUser: {},
    SIGN_UP_REQUEST_TABLE: SIGN_UP_REQUEST_TABLE,
    modalKey: 0,
  }),
  methods: {

    async approveUser(user, approvalType) {
      let regReqId = typeof user.registRequestId !== Number ? parseInt(user.registRequestId) : user.registRequestId;
      let params = {
        registRequestId: regReqId,
        approvalYn: approvalType
      }
      let type = approvalType === "Y" ? "승인" : "거절";
      if (confirm("[" + user.memberNm + "]" + " 사용자를 "+ type + "하시겠습니까?")) {
        try {
          const {data} = await axiosInstance.get('/admin/registRequestApproval', {params});
          for (let i in this.data) {
            if (this.data[i].registRequestId == user.registRequestId) {
              this.data[i].approvalYn = approvalType;
            }
          }
          this.$emit("refreshList");
          alert(data);
        } catch (e) {
          console.log(e);
        }
      }
    },

    // 명함img의 str값
    openModal(user){
      this.selectedUser = user;

      this.modalKey++;
      this.isShow = true;
    }
  }
}
</script>

<style scoped>
.salseCard {
  margin-right: 4px;
  margin-left: 2px;
}
</style>