<template>
  <search-bar-layout>
    <template v-slot:contents>
      <v-row>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="4">기준년월</v-col>
          <v-text-field
            v-model="settlementDate"
            outlined dense hide-details
            background-color="white"
          />
        </v-col>
        <v-col class="d-flex justify-center align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-radio-group row v-model="searchType">
            <v-radio label="지급액" value="A" />
            <v-radio label="미지급액" value="B" />
            <v-radio label="렌카" value="C" />
          </v-radio-group>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-12">
          <v-col cols="5" v-if="searchType === 'A'">지급완료일</v-col>
          <v-col cols="5" v-else>지급예정일</v-col>
          <v-text-field
              v-model="payDate"
              outlined dense hide-details
              background-color="white"
          />
        </v-col>
        <v-col class="d-flex justify-center align-center col-xl-1 col-lg-1 col-md-3 col-12">
          <v-btn class="grey white--text" :disabled="isDisabled" v-if="!searching" @click="search">조회</v-btn>
          <v-btn class="red white--text" v-else @click="initData">초기화</v-btn>
        </v-col>
        <v-col class="d-flex justify-center align-center">
          <v-checkbox v-model="check" label="해당 기준연월 건만 보기" @change="$emit('check', check)" :disabled="checkboxDisabled" />
        </v-col>
      </v-row>

      <v-slide-y-transition>
        <v-divider class="mb-2" v-show="searching"/>
      </v-slide-y-transition>
      <v-slide-y-transition>
        <search-area title="상세조건" v-show="searching">
          <field-input title="상태">
            <v-select
                :items="filteredStatusList" v-model="status"
                background-color="white" menu-props="auto"
                hide-details outlined dense
                @change="$emit('changeStatus', status)"
            />
          </field-input>
          <field-input title="회원명">
            <v-text-field
                v-model="nm"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </field-input>
          <field-input title="주민등록번호">
            <v-text-field
                v-model="ssno"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </field-input>
        </search-area>
      </v-slide-y-transition>
    </template>

    <template v-slot:button>
      <v-slide-y-transition v-show="searching">
        <search-btn
            v-if="searching"
            @click="$emit('filter', status, nm, ssno)"
        />
      </v-slide-y-transition>
    </template>
  </search-bar-layout>
</template>

<script>

import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBtn from "@/components/Search/SearchBtn";
import _ from "lodash";

export default {
  components: {SearchBtn, FieldInput, SearchArea, SearchBarLayout},
  computed: {
    isDisabled() {
      const settlementDate = this.settlementDate;
      const payDate = this.payDate;

      // 정규 표현식을 이용한 날짜 형식 검증
      const isValidSettlementDate = settlementDate && settlementDate.length === 6 && /[0-9]{6}/.test(settlementDate) && this.monthCheck(settlementDate);
      const isValidPayDate = payDate && payDate.length === 8 && /[0-9]{8}/.test(payDate) && this.monthCheck(payDate);

      return !(isValidSettlementDate || isValidPayDate);
    },
    filteredStatusList() {
      return this.setRewardStatus();
    }
  },
  props: ["searching", "REWARD_STATUS", "checkboxDisabled"],
  data: () => ({
    settlementDate: "",
    payDate: "",
    status: "ALL",
    nm: "",
    ssno: "",
    check: false,
    searchType: "B",
  }),
  watch : {
    checkboxDisabled : function(value) {
      if(value) this.check = false;
    },
    searchType(value) {
      this.$emit("searchType", value);
    }
  },
  methods: {
    // 기준연월 유효성 체크
    monthCheck(value) {
      if (value.length === 6) {
        // settlementDate의 경우: 연도(앞 4자리), 월(뒤 2자리)
        const month = +value.slice(4, 6);
        return month > 0 && month < 13;
      } else if (value.length === 8) {
        // payDate의 경우: 연도(앞 4자리), 월(중간 2자리), 일(뒤 2자리)
        const month = +value.slice(4, 6);
        const day = +value.slice(6, 8);
        return month > 0 && month < 13 && day > 0 && day <= 31;
      }
      return false;
    },
    search() {
      if (_.isEmpty(this.settlementDate || this.payDate)) {
        alert("기준년월 혹은 지급예정일/지급완료일을 입력해주세요.");
        return false;
      } else {
        const params = {
          searchType: this.searchType,
          settlementDate: this.settlementDate,
          payDate: this.payDate
        }
        this.$emit("search", params);
      }
    },
    initData() {
      this.settlementDate = "";
      this.payDate = "";
      this.searchType = "B";
      this.status = "ALL";
      this.$emit('initData');
    },
    setRewardStatus() {
      let statusList = this.REWARD_STATUS;
      if (this.searchType === "A") {
        this.status = "SUCCESS";
        return statusList.filter(item => item.value === "SUCCESS");
      } else if (this.searchType === "B") {
        return statusList.filter(item => item.value !== "SUCCESS");
      }
      return statusList;
    }
  }
}
</script>

<style>
.todayDelete {
  margin-left: auto;
  color: red;
  text-decoration-line: underline;
  cursor: pointer;
}
</style>