<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center col-xl-2 col-lg-4 col-md-6 col-12">
          <v-col cols="5">자료구분</v-col>
          <v-select
              v-model="srhType3"
              :items="srhType3List"
              background-color="white"
              item-text="dname"
              item-value="dvalue"
              hide-details outlined dense
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-4 col-md-6 col-12">
          <v-col cols="5">
            <v-select
                v-if="srhType3 === 'D'"
                v-model="request"
                :items="requestList"
                item-text="aname"
                item-value="avalue"
                hide-details dense
            />
            <v-select
                v-else-if="srhType3 === 'C'"
                v-model="campaign"
                :items="campaignList"
                item-text="campaignNm"
                item-value="campaign"
                hide-details dense
            />
          </v-col>
          <date-input
              class="date-picker"
              :start-date="startDate"
              :end-date="endDate"
              @updateDate="updateDate"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-4 col-md-6 col-12">
          <v-col cols="5">상태</v-col>
          <v-select
            v-model="state"
            :items="stateList"
            background-color="white"
            item-text="bname"
            item-value="bvalue"
            hide-details outlined dense
            />
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-4 col-md-6 col-12">
          <v-col cols="5">
            <v-select
                v-model="srhType"
                :items="srhTypeList"
                item-text="cname"
                item-value="cvalue"
                hide-details dense
            />
          </v-col>
          <div v-if="srhType === 'JI'" style="display: flex; justify-content: center; justify-items: center;">
            <v-select
                v-model="guideInsurerCd"
                :items="guideInsurerCdList"
                item-text="insurerNm"
                item-value="insurerCd"
                background-color="white"
                hide-details outlined dense
                style="margin-right: 3px;"
            />
            <v-text-field
                background-color="white"
                color="black"
                hide-details outlined dense
                v-model="srhNm"
            />
          </div>
          <v-text-field
              v-else
              background-color="white"
              color="black"
              hide-details outlined dense
              v-model="srhNm"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-4 col-md-6 col-12">
          <v-col cols="5">상담결과</v-col>
          <v-select
              v-model="consultResult"
              :items="consultResultList"
              background-color="white"
              item-text="text"
              item-value="value"
              hide-details outlined dense
          />
        </v-col>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "../../../../layouts/SearchBarLayout";
import DateInput from "../../../../components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";
import SearchBtn from "../../../../components/Search/SearchBtn";
import SearchArea from "../../../../components/Search/SearchArea";

export default {
  props: ["consultResultList"],
  mixins: [DateMixin],
  components: {SearchArea, DateInput, SearchBarLayout, SearchBtn},
  beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-3);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  data: () => ({
    tgKey:0,
    startDate: "",
    state:'',
    request: 'CDATE',
    campaign: 'EDATE',
    endDate: "",
    srhNm:"",
    srhType:'CU',
    srhType3: 'D',
    requestList: [
      {aname: '요청일자', avalue:'CDATE'},
      {aname: '만기일자', avalue:'EDATE'},
    ],
    campaignList: [
      { campaignNm: '계약안내일자', campaign: 'EDATE'},
      { campaignNm: '등록일자', campaign: 'CDATE' }
    ],
    stateList: [
      {bname: '전체', bvalue:''},
      {bname: '대기', bvalue:'WAIT'},
      {bname: '진행중', bvalue:'PROC'},
      {bname: '종료', bvalue:'DONE'},
    ],
    srhTypeList: [
      {cname: '고객', cvalue:'CU'},
      {cname: '설계사명', cvalue:'FA'},
      {cname: '차량번호', cvalue:'CN'},
      {cname: '호전환코드', cvalue:'JI'},
      {cname: '진행자', cvalue:'CT'}
    ],
    srhType3List: [
      {dname: '설계지원', dvalue:'D'},
      {dname: '캠페인', dvalue:'C'},
    ],
    guideInsurerCd: "HD",
    guideInsurerCdList: [
      {insurerNm: "현대해상", insurerCd: "HD"},
      {insurerNm: "DB손보", insurerCd: "DB"},
      {insurerNm: "KB손보", insurerCd: "KB"},
      {insurerNm: "한화손보", insurerCd: "HW"},
      {insurerNm: "메리츠화재", insurerCd: "MZ"},
      {insurerNm: "흥국화재", insurerCd: "HK"},
      {insurerNm: "하나손보", insurerCd: "HN"},
      {insurerNm: "AXA손보", insurerCd: "AXA"}
    ],
    consultResult: ""
  }),
  watch: {
    // srhType 변경 시 srhNm 초기화
    srhType(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.srhNm = "";
      }
    }
  },
  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    getSearch() {
      const params = {
        srhType1: this.srhType3 === "D" ? this.request : this.campaign,
        srhType2: this.srhType,
        srhType3: this.srhType3,
        srhFrDt: this.startDate.replaceAll('-', ''),
        srhToDt: this.endDate.replaceAll('-', ''),
        srhNm: this.srhNm.replace(/\s/g, ''),
        state: this.state,
        guideInsurerCd: this.guideInsurerCd,
        consultResult: this.consultResult
      }
      this.tgKey++;
      this.$emit('getSearch', params, this.tgKey);
    }
  }
}
</script>

<style scoped>
.date-picker {
  flex: 1 1 auto;
}
</style>