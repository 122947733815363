<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="작업 기준일">
        <v-col class="d-flex align-center col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
          <v-col cols="9">
            <date-input
                :start-date="fromDate"
                :end-date="toDate"
                @updateDate="updateDate"
            />
          </v-col>
        </v-col>
      </search-area>

    </template>
    <template v-slot:button>
      <search-btn @click="getCarExpirInfoJob"/>
    </template>
  </search-bar-layout>
</template>

<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";

export default {
  mixins: [SearchBarMixin, CodeMixin, DateMixin],
  components: {DateInput, SearchArea, SearchBarLayout},
  async beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-30);
    this.fromDate = fromDate;
    this.toDate = toDate;
  },
  data: () => ({
    fromDate: "",
    toDate: "",
  }),
  computed: {

  },
  methods: {
    updateDate(values) {
      this.fromDate = values[0];
      this.toDate = values[1];
    },
    getCarExpirInfoJob() {

      const params = {
        fromDate : this.fromDate.replace(/[^0-9]/gi, ""),
        toDate : this.toDate.replace(/[^0-9]/gi, ""),
      }
      this.$emit('getCarExpirInfoJob', params)
    }
  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>