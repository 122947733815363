<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-row>
          <v-col class="d-flex justify-center align-center col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12">
            <v-col cols="4">기준연월</v-col>
            <v-text-field
              v-model="baseYM"
              outlined dense hide-details
              background-color="white"
            />
          </v-col>
        </v-row>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSearch"/>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout.vue";
import SearchArea from "@/components/Search/SearchArea.vue";
import SearchBtn from "@/components/Search/SearchBtn.vue";

export default {
  components: {SearchBtn, SearchArea, SearchBarLayout},
  data() {
    return {
      baseYM: "",
    }
  },
  computed: {
    nowDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;
      return `${year}` + `${month < 10 ? 0 + "" + month : month}`;
    }
  },
  mounted() {
    this.baseYM = this.nowDate;
  },
  methods: {
    getSearch() {
      const params = {
        baseYM: this.baseYM
      };
      this.$emit("search", params);
    }
  }
}
</script>