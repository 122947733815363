import func from "../util/func";

export default {
	methods: {
		addZero(month) {
			return month.toString().length > 1 ? `${month}` : `0${month}`;
		},
		getKST(date) {
			const now = new Date(date);
			// now.setHours(now.getHours() + 9);
			return now.toLocaleString();
		},
		// 20220919 -> 2022-09-19
		getDateDashFormat(date) {
			if(!date) return "";
			return date = date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3');
		},
		getDateNoSecFormat(date) {
			// 날짜 및 시간 두자리로 저장
			const now = new Date(date);
			let month = func.lpad(now.getMonth() + 1, 2, "0");
			let day = func.lpad(now.getDate(), 2, "0");
			let hour = func.lpad(now.getHours() + 9, 2, "0");
			let minute = func.lpad(now.getMinutes(), 2, "0");

			return now.getFullYear() + '-' + month + '-' + day + ' ' + hour + ':' + minute;
		},
		setDate(m) {
			const addMonth = m ? m : 1;
			const date = new Date();
			date.setMonth(date.getMonth() - 1);
			const [year, month] = [date.getFullYear(), date.getMonth()];

			const startDate = `${year}-${this.addZero(month + 1)}-01`;
			const endDate = `${year}-${this.addZero(month + addMonth)}-${new Date(year, month + addMonth, 0).getDate()}`;

			return {startDate, endDate}
		},
		setDateDiff(dd) {
			let today = new Date();
			let mday = new Date(today.setDate(today.getDate() + dd));
			let [myear, mmonth,mdd] = [mday.getFullYear(), mday.getMonth(), mday.getDate()];
			let fromDate =`${myear}-${this.addZero(mmonth + 1)}-${this.addZero(mdd)}`;

			today = new Date();
			[myear, mmonth,mdd] = [today.getFullYear(), today.getMonth(), today.getDate()];
			let toDate =`${myear}-${this.addZero(mmonth + 1)}-${this.addZero(mdd)}`;
			let tmpDate = fromDate;
			if (fromDate > toDate) {
				tmpDate = toDate;
				toDate  = fromDate;
				fromDate = tmpDate;
			}
			return {fromDate, toDate}
		},
		// {fromDate, toDate} = {yyyymm, yyyymm} 형식
		setDateMonthNoDash(m) {
			const addMonth = m ? m : 1;
			const date = new Date();
			date.setMonth(date.getMonth() - 1);
			const [year, month] = [date.getFullYear(), date.getMonth()];

			// 월 + 1이 12일 경우
			if ((month + 1) === 12) {
				const fromDate = `${year}${this.addZero(month - addMonth)}`;
				const toDate = `${year}${this.addZero(month + 1)}`;

				return {fromDate, toDate};
				// 나머지
			} else {
				const fromDate = `${year - 1}${this.addZero(month + (addMonth - 9))}`;
				const toDate = `${year}${this.addZero(month + 1)}`;

				return {fromDate, toDate};
			}
		},
		getDateNoDashFormat(date) {
			let year = date.getFullYear();
			let month = date.getMonth()+1;
			let day = date.getDate();

			return `${year}` + `${month < 10 ? 0 + "" + month : month}` + `${day < 10 ? 0 + "" + day : day}`;
		},
		// 2022-09-19 -> 20220919
		getNoDashFormat(date) {
			return date = date.split("-").join("");
		},

		// yyyymmdd 형식의 날짜 정상여부 확인
		checkValidDate(dateString){
			const year = parseInt(dateString.substr(0, 4), 10);
			const month = parseInt(dateString.substr(4, 2), 10);
			const day = parseInt(dateString.substr(6, 2), 10);

			const date = new Date(year, month - 1, day); // month는 0부터 시작하므로 -1

			return (
				date.getFullYear() === year &&
				date.getMonth() === month - 1 &&
				date.getDate() === day
			);
		}
	}
}