<template>
  <div class="d-flex align-center">
    <v-menu
        v-model="isShow"
        transition="scale-transition"
        :close-on-content-click="false"
        offset-y min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-if="isSingleDate"
            v-model="date"
            v-bind="attrs" v-on="on"
            append-icon="calendar_today"
            outlined dense readonly hide-details
            class="dateAlign" :style="{backgroundColor: bgColor}"
            @click:append="isShow = !isShow"
            :disabled="disabled"
        />
        <v-text-field
            v-else
            v-model="dateRangeText"
            v-bind="attrs" v-on="on"
            append-icon="calendar_today"
            outlined dense readonly hide-details
            class="dateAlign" :style="{backgroundColor: bgColor}"
            @click:append="isShow = !isShow"
            :disabled="disabled"
        />
      </template>
      <v-date-picker
          v-if="isSingleDate"
          v-model="date" locale="ko-kr"
          @input="closeClick"
          :show-current="false"
          :day-format="removeBackWord"
          :disabled="disabled"
      />
      <v-date-picker
          v-else
          v-model="dates" locale="ko-kr" range
          @input="closeTwoClick"
          :show-current="false"
          :day-format="removeBackWord"
          :disabled="disabled"
      />
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["startDate", "endDate", "disabled", "isSingleDate", "singleDate", "type"],
  data: () => ({
    isShow: false,
    isSecondClick: false,
    date: '',
    dates: ['', ''],
    backupDates: ['', ''],
    bgColor: "white",
  }),
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
  },
  created() {
    if (this.isSingleDate) {
      this.date = this.singleDate;
    } else {
      this.dates = [this.startDate, this.endDate];
    }
  },
  methods: {
    validation() {
      if (this.isSingleDate) {
        this.$emit('updateDate', this.date, this.type ? this.type : "");
      } else {
        if (this.isSecondClick) {
          this.$emit('updateDate', [this.dates[0], '']);
        } else {
          const s = this.dates[0].split('-');
          const e = this.dates[1].split('-');

          const diffDay = (new Date(e[0], e[1], e[2]) - new Date(s[0], s[1], s[2])) / 1000 / 60 / 60 / 24;

          if (diffDay < 0) {
            let temp = this.dates[0]
            this.dates[0] = this.dates[1];
            this.dates[1] = temp;
            this.validation();
          } else {
            if (diffDay > 62) {
              alert("일자 범위는 두달 이내로 설정해주세요.");
              this.dates[1] = '';
              this.$emit('updateDate', [this.dates[0], this.dates[1]]);
            } else {
              this.$emit('updateDate', [this.dates[0], this.dates[1]])
            }
          }
        }
      }
    },
    addZero(month) {
      return month.toString().length > 1 ? `${month}` : `0${month}`;
    },
    removeBackWord(d) {
      return new Date(d).getDate();
    },
    closeTwoClick() {
      if (this.isSecondClick) {
        this.isSecondClick = false;
        this.isShow = false;
      } else {
        this.isSecondClick = true;
      }
      this.validation();
    },
    closeClick() {
      this.validation();
      this.isShow = false;
    }
  },
}
</script>

<style>
.dateAlign input {
  text-align: left !important;
}
</style>