<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="필수조건">
        <!--        <v-col :class="CLASS1">-->
        <v-col class="d-flex align-center col-xl-6 col-md-6 col-12">
          <v-col cols="2">조회기간</v-col>
          <v-col>
            <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-5 col-md-5 col-12">
          <v-col cols="3">회원 이름</v-col>
          <v-col cols="9">
            <v-text-field
                v-model="memberNm"
                @keypress.enter="searchBlackList"
                outlined dense hide-details
                background-color="white" menu-props="auto"
            />
          </v-col>
        </v-col>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="searchBlackList"/>
    </template>
  </search-bar-layout>
</template>

<script>
import SearchArea from "@/components/Search/SearchArea";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import SearchBtn from "@/components/Search/SearchBtn";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";
import StringMixin from "@/mixin/StringMixin";

export default {
  mixins: [DateMixin, StringMixin, SearchBarMixin],
  components: {SearchBtn, DateInput, SearchBarLayout, SearchArea},
  beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-30);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  data: () => ({
    memberNm: "",
    startDate: "",
    endDate: ""
  }),
  methods: {
    searchBlackList() {
      this.$emit('getSearch', {
        memberNm : this.memberNm,
        startDate: this.replaceOnlyNum(this.startDate),
        endDate: this.replaceOnlyNum(this.endDate)
      })
    },
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },

  }
}
</script>

<style scoped>

</style>