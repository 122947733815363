export default {
	methods : {
		getHeader(headers) {
			return headers.map(header => ({
				text: header[0],
				value: header[1],
				align: 'center',
				class: 'headers text-no-wrap',
				divider: true,
				fixed: true,
			}))
		},
		getDataByValue(value) {
			if(!value.toString()) return '';

			try {
				const date = new Date(value);
				return date.toLocaleString();
			} catch (e) {
				console.log(e);
				return '';
			}
		},
		getTelNum(telNum) {
			if(!telNum) return "";
			return telNum.length === 11 ? `${telNum.slice(0, 3)}-${telNum.slice(3, 7)}-${telNum.slice(7)}` : telNum;
		},
		inputComma(num) {
			if(!num) return 0;
			return num && num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		},
		getDotsBirthday(value) {
			if(value === null) return;
			return  value.length === 8
				? `${value.slice(0,4)}.${value.slice(4,6)}.${value.slice(6,8)}`
				: value
		},
		setDateBySlash(value) {
			if(value === null) return;
			return  value.length === 8
				? `${value.slice(0,4)}/${value.slice(4,6)}/${value.slice(6,8)}`
				: value
		},
		changedData(data, value, changeData) {
			if (data === value) {
				return data = changeData;
			}
		},
		textLengthOverCut(text, length, lastText) {
			if (text === null || text.length <= length) {
				return text;
			} else if (text.length > length) {
				text = text.substr(0, length) + lastText;
				return text;
			}
		},
		contentsDataFormatter(date) {
			let d = date ? date : '';
			return date = d ? `${d.slice(0, 4)}-${d.slice(5, 7)}-${d.slice(8, 10)} ${d.slice(11, 19)}` : ''; // 2021-11-02 17:47:13
		}
	}
}