<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area title="필수조건">
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
          <v-select
              :items="DATE_TYPE"
              background-color="white"
              v-model="searchType" menu-props="auto"
              hide-details outlined dense
              @change="changeSearchType(searchType)"
          />
          <v-col cols="9">
            <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
                :disabled="disableDateInput"
            />
          </v-col>
        </v-col>
        <v-col class="d-flex align-center col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
        <v-col cols="5">
          <span>검수대상 조회범위</span>
        </v-col>
          <v-radio-group
              v-model="checkRage"
              row
          >
            <v-radio label="전체자료"  value="ALL"/>
            <v-radio label="검수대상건" value="SEL"/>
          </v-radio-group>
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12">
          <v-col cols="4">
            <span>점검결과</span>
          </v-col>
          <v-select
              v-model="searchCheckMap"
              :items="checkMappCd"
              background-color="white"
              item-text="uname"
              item-value="uvalue"
              hide-details outlined dense
              @change="checkMappCdChange"
          />
        </v-col>
        <v-col class="d-flex align-center col-xl-2 col-lg-3 col-md-6 col-sm-12 col-12">
          <v-col cols="4">
            <span>자료구분</span>
          </v-col>
          <v-select
              v-model="dtType"
              :items="dtTypeList"
              background-color="white"
              item-text="dtTypeNm"
              item-value="dtType"
              hide-details outlined dense
          />
        </v-col>
      </search-area>

      <v-divider class="mb-2"/>

      <search-area title="유입고객 정보">
        <field-input title="고객명">
          <v-text-field
              v-model="customerNm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="고객 전화번호">
          <v-text-field
              v-model="customerTelNum"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="차량번호">
          <v-text-field
              v-model="customerCarNo"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
      </search-area>

      <search-area title="회원정보">
        <field-input title="회원명">
          <v-text-field
              v-model="recipientNm"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="회원 전화번호">
          <v-text-field
              v-model="recipientTelNum"
              outlined dense hide-details
              background-color="white" menu-props="auto"
          />
        </field-input>
        <field-input title="보험사">
          <v-select
              v-model="searchType1"
              :items="insurerCd"
              background-color="white"
              item-text="uname"
              item-value="uvalue"
              hide-details outlined dense
          />
        </field-input>
      </search-area>
    </template>
    <template v-slot:button>
      <search-btn @click="getSettlementDetail"/>
    </template>
  </search-bar-layout>
</template>
<script>
import FieldInput from "@/components/Form/FieldInput";
import SearchBarLayout from "@/layouts/SearchBarLayout";
import SearchArea from "@/components/Search/SearchArea";
import SearchBarMixin from "@/mixin/SearchBarMixin";
import CodeMixin from "@/mixin/CodeMixin";
import DateInput from "@/components/Form/DateInput";
import DateMixin from "@/mixin/DateMixin";
import _ from "lodash";

export default {
  mixins: [SearchBarMixin, CodeMixin, DateMixin],
  components: {DateInput, SearchArea, SearchBarLayout, FieldInput},
  async beforeMount() {
    const { startDate, endDate } = this.setDate(1);
    this.startDate = startDate;
    this.endDate = endDate;
    this.searchType1 = 'ALL';
  },
  async created() {
    this.DATE_TYPE = await this.getSelectList("SETTLEMENT_DETAIL_TYPE");
  },
  data: () => ({
    DATE_TYPE: [],
    startDate: "",
    endDate: "",
    searchType: "CONTRACT_DATE",
    customerNm: "",
    customerTelNum: "",
    customerCarNo: "",
    recipientNm: "",
    recipientTelNum: "",
    checkRage: "ALL",
    insurerCd: [
      {uname: '전체', uvalue: 'ALL'},
      {uname: '현대해상', uvalue: 'HD'},
      {uname: 'DB손해보험', uvalue: 'DB'},
      {uname: 'KB손해보험', uvalue: 'KB'},
      {uname: '하나손해보험', uvalue: 'HN'},
      {uname: '흥국화재', uvalue: 'HK'},
      {uname: '캐롯손해보험', uvalue: 'CR'},
      {uname: '한화손해보험', uvalue: 'HW'},
      {uname: '롯데손해보험', uvalue: 'LO'},
      {uname: 'AXA손해보험', uvalue: 'AXA'},
      {uname: '메리츠화재', uvalue: 'MZ'},
      {uname: '삼성화재', uvalue: 'SS'},
      {uname: 'MG손해보험', uvalue: 'MG'},
    ],
    searchCheckMap:"ALL",
    checkMappCd: [
      {uname: '전체', uvalue: 'ALL'},
      {uname: '정상', uvalue: '정상'},
      {uname: '오류', uvalue: '오류'},
    ],
    dtType: "ALL",
    dtTypeList: [
      { dtTypeNm: "전체", dtType: "ALL" },
      { dtTypeNm: "월정산", dtType: "MONTH" },
      { dtTypeNm: "조기정산", dtType: "EARLY" }
    ],
    disableDateInput: false
  }),
  computed: {

  },
  methods: {
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    getSettlementDetail() {

      const params = {
        startDate : this.startDate.replace(/[^0-9]/gi, ""),
        endDate : this.endDate.replace(/[^0-9]/gi, ""),
        searchType : this.searchType,
        contractInsurer : this.searchType1,
        dtType: this.dtType
      }

      if (this.searchType === "ALL") {
        if (_.isEmpty(this.customerNm) &&
            _.isEmpty(this.customerTelNum) &&
            _.isEmpty(this.customerCarNo) &&
            _.isEmpty(this.recipientNm) &&
            _.isEmpty(this.recipientTelNum))
        {
          alert("고객 또는 회원정보를 입력해주세요.");
          return false;
        }
      }

      if(this.customerNm) params["customerNm"] = this.customerNm;
      if(this.customerTelNum) params["customerTelNum"] = this.customerTelNum.replaceAll("-", "");
      if(this.customerCarNo) params["customerCarNo"] = this.customerCarNo;
      if(this.recipientNm) params["recipientNm"] = this.recipientNm;
      if(this.recipientTelNum) params["recipientTelNum"] = this.recipientTelNum.replaceAll("-", "");
      if(this.checkRage) params["checkRage"] = this.checkRage;

      this.$emit('getSettlementDetail', params)
    },
    checkMappCdChange() {
      this.$emit('setCheckMappCdChange', this.searchCheckMap)
    },
    changeSearchType(type) {
      this.disableDateInput = type === "ALL";
    }
  }
}
</script>

<style scoped>
.desc {
  margin-left: auto;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.6);
}
</style>