<template>
  <search-bar-layout>
    <template v-slot:contents>
      <search-area>
        <v-col class="d-flex align-center justify-center col-xl-4 col-lg-4 col-md-4 col-12">
          <v-col class="col-4">
            <span>작업구분</span>
          </v-col>
          <v-col class="col-8">
            <v-radio-group v-model="wkType" row>
              <v-radio label="대상자료선정"  value="NEW" />
              <v-radio label="기입력자료"  value="EXT" />
            </v-radio-group>
          </v-col>
        </v-col>

        <v-col class="d-flex align-center justify-center col-xl-4 col-lg-3 col-md-3 col-12">
          <v-col class="col-4">
            <span>상태</span>
          </v-col>
          <v-col class="col-xl-8 col-lg-9 col-md-9">
            <v-select
                v-model="stateCd"
                :items="stateList"
                item-text="stateNm"
                item-value="stateCd"
                outlined dense hide-details
                background-color="white"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center justify-center col-xl-4 col-lg-5 col-md-5 col-12">
          <v-col class="col-xl-4 col-lg-4 col-md-4">
            <v-select
                v-model="dayType"
                :items="dayTypeList"
                item-text="dayTypeNm"
                item-value="dayType"
                dense hide-details
            />
          </v-col>
          <v-col class="col-xl-8 col-lg-8 col-md-8">
            <date-input
                :start-date="startDate"
                :end-date="endDate"
                @updateDate="updateDate"
            />
          </v-col>
        </v-col>
      </search-area>

      <search-area>
        <v-col class="d-flex align-center justify-center col-xl-4 col-lg-4 col-md-4 col-12">
          <v-col class="col-4">
            <span>고객명</span>
          </v-col>
          <v-col class="col-8">
            <v-text-field
              v-model="customerNm"
              outlined dense hide-details
              background-color="white"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center justify-center col-xl-4 col-lg-4 col-md-4 col-12">
          <v-col class="col-4">
            <span>고객 전화번호</span>
          </v-col>
          <v-col class="col-8">
            <v-text-field
                v-model="customerTelNum"
                outlined dense hide-details
                background-color="white"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center justify-center col-xl-4 col-lg-4 col-md-4 col-12">
          <v-col class="col-4">
            <span>차량번호</span>
          </v-col>
          <v-col class="col-8">
            <v-text-field
                v-model="customerCarNo"
                outlined dense hide-details
                background-color="white"
            />
          </v-col>
        </v-col>
      </search-area>

      <search-area>
        <v-col class="d-flex align-center justify-center col-xl-4 col-lg-4 col-md-4 col-12">
          <v-col class="col-4">
            <span>회원명</span>
          </v-col>
          <v-col class="col-8">
            <v-text-field
                v-model="memberNm"
                outlined dense hide-details
                background-color="white"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center justify-center col-xl-4 col-lg-4 col-md-4 col-12">
          <v-col class="col-4">
            <span>회원 전화번호</span>
          </v-col>
          <v-col class="col-8">
            <v-text-field
                v-model="memberTelNum"
                outlined dense hide-details
                background-color="white"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center justify-center col-xl-4 col-lg-4 col-md-4 col-12">
          <v-col class="col-4">
            <span>보험사</span>
          </v-col>
          <v-col class="col-8">
            <v-select
              v-model="insCd"
              :items="insList"
              item-text="insCdNm"
              item-value="insCd"
              outlined dense hide-details
              background-color="white"
            />
          </v-col>
        </v-col>
      </search-area>

      <search-area>
        <v-col class="d-flex align-center justify-center col-xl-4 col-lg-4 col-md-4 col-12">
          <v-col class="col-4">
            <span>계약채널</span>
          </v-col>
          <v-col class="col-8">
            <v-select
                v-model="joinChannel"
                :items="joinChannelList"
                item-text="joinChannelNm"
                item-value="joinChannel"
                outlined dense hide-details
                background-color="white"
            />
          </v-col>
        </v-col>

        <v-col class="d-flex align-center justify-end col-xl-8 col-lg-8 col-md-8 col-12">
          <v-btn style="" @click="search">조회</v-btn>
          <v-btn style="margin-left: 10px;" @click="showSavePopup">
            저장
          </v-btn>
          <v-btn style="margin-left: 10px;" :disabled="stateCd !== 'NR'" @click="deleteData">삭제</v-btn>
        </v-col>
        <settlement-early-save-popup
            :is-show="isShowSave"
            :save-data="selectList"
            @close="closePopup"
            @refresh="refreshData"
        />
      </search-area>
    </template>
  </search-bar-layout>
</template>
<script>
import SearchBarLayout from "@/layouts/SearchBarLayout";
import DateInput from "@/components/Form/DateInput";
import SearchArea from "@/components/Search/SearchArea";
import constant from "@/util/constant";
import DateMixin from "@/mixin/DateMixin";
import SettlementEarlySavePopup from "@/pages/Settlement/components/SettlementEarly/Popup/SettlementEarlySavePopup";
import {axiosInstance} from "@/util/AxiosModule";

export default {
  props: ["selectList"],
  mixins: [DateMixin],
  components: {SettlementEarlySavePopup, SearchArea, DateInput, SearchBarLayout},
  async beforeMount() {
    const { fromDate, toDate } = this.setDateDiff(-8);
    this.startDate = fromDate;
    this.endDate = toDate;
  },
  mounted() {
    this.setStateList();
    this.setDayTypeList();
    if (this.wkType === "NEW") {
      this.stateCd = "YR"
      if (this.stateCd === "YR") {
        this.dayTypeList = this.dayTypeList.filter(item => item.dayType === "contractDt");
        this.stateList = this.stateList.filter(item => item.stateCd === "YR");
      }
    }
  },
  watch: {
    wkType(value) {
      this.setStateList();
      this.setDayTypeList();
      if (value === "NEW") {
        this.stateList = this.stateList.filter(item => item.stateCd === "YR");
        this.stateCd = "YR";
      }
      else if (value === "EXT") {
        this.stateList = this.stateList.filter(item => item.stateCd !== "YR");
        this.stateCd = "NR";
      }
    },
    stateCd(value) {
      this.setDayTypeList();
      if (value === "NR") {
        this.dayTypeList = this.dayTypeList.filter(item => item.dayType !== "payCompleteDt");
        this.dayType = "contractDt";
      } else if (value === "RR") {
        this.dayTypeList = this.dayTypeList.filter(item => item.dayType !== "payCompleteDt");
        this.dayType = "inputDt"
      } else if (value === "RC") {
        this.dayTypeList = this.dayTypeList.filter(item => item.dayType !== "payDueDt");
        this.dayType = "payCompleteDt";
      } else if (value === "YR") {
        this.dayTypeList = this.dayTypeList.filter(item => item.dayType === "contractDt");
        this.dayType = "contractDt";
      } else if (value === "PC") {
        this.dayType = "payCompleteDt";
      } else if (value === "SC") {
        this.dayType = "payCompleteDt";
      }
    }
  },
  data() {
    return {
      wkType: "NEW",
      stateCd: "YR",
      stateList: [],
      dayType: "contractDt",
      dayTypeList: [],
      startDate: "",
      endDate: "",
      insCd: "",
      insList: constant.GUIDE_INSURER_CODE,
      joinChannel: "",
      joinChannelList: [
        { joinChannelNm: "전체", joinChannel: "" },
        { joinChannelNm: "CM", joinChannel: "CM" },
        { joinChannelNm: "TM", joinChannel: "TM" }
      ],
      customerCarNo: "",
      customerNm: "",
      customerTelNum: "",
      memberNm: "",
      memberTelNum: "",
      isShowSave: false
    }
  },
  methods: {
    setStateList() {
      this.stateList =
        [
          { stateNm: "미반영", stateCd: "YR" },
          { stateNm: "정상반영", stateCd: "NR" },
          { stateNm: "지급완료", stateCd: "PC" },
          { stateNm: "정산완료", stateCd: "SC" },
          { stateNm: "환수반영", stateCd: "RR" },
          { stateNm: "환수완료", stateCd: "RC" }
        ]
    },
    setDayTypeList() {
      this.dayTypeList =
        [
          { dayTypeNm: "지급예정일자", dayType: "payDueDt" },
          { dayTypeNm: "지급일자", dayType: "payCompleteDt" },
          { dayTypeNm: "입력일자", dayType: "inputDt" },
          { dayTypeNm: "계약일자", dayType: "contractDt" }
        ]
    },
    updateDate(values) {
      this.startDate = values[0];
      this.endDate = values[1];
    },
    search() {
      const params = {
        customerCarNo: this.customerCarNo.replace(/\s/g, ''),
        customerNm: this.customerNm.replace(/\s/g, ''),
        customerTelNum: this.customerTelNum.replace(/\D/g, ""),
        dayType: this.dayType,
        endDate: this.endDate.replaceAll("-", ""),
        insCd: this.insCd,
        joinChannel: this.joinChannel,
        memberNm: this.memberNm.replace(/\s/g, ''),
        memberTelNum: this.memberTelNum.replace(/\D/g, ""),
        startDate: this.startDate.replaceAll("-", ""),
        stateCd: this.stateCd,
        wkType: this.wkType,
      };
      this.$emit("search", params);
    },
    showSavePopup() {
      this.isShowSave = true;
    },
    async deleteData() {
      let earlyList = [];
      earlyList = this.selectList;
      if(confirm(`${earlyList.length}개의 데이터를 삭제하시겠습니까?`)) {
        await axiosInstance.delete("/admin/settlement/delEarlySettlement", {
          data: earlyList
        })
            .then(() => {
              alert("삭제 완료되었습니다.");
              this.refreshData();
            })
            .catch(e => {
              console.log(e);
            })
      }
    },
    closePopup(work) {
      if (work === "close") {
        this.isShowSave = false;
      } else if (work === "save") {
        this.isShowSave = false;
        this.refreshData();
      }
    },
    refreshData() {
      this.wkType = "NEW";
      this.stateCd = "YR";
      this.$emit("refresh");
    }
  }
}
</script>