<template>
  <search-page-layout title="지급처리(신)">
    <template v-slot:searchBar>
      <new-payment-search-bar
        :searching="searching"
        :REWARD_STATUS="REWARD_STATUS"
        :checkbox-disabled="tableData.length < 1"
        @search="search"
        @initData="initData"
        @filter="filter"
        @check="chgOnlySettlementDate"
        @changeStatus="changeStatus"
        @searchType="getSearchType"
      />
    </template>
    <template v-slot:table>
      <new-payment-table
        :loading="loading"
        :showButton="showButton"
        :search-type="searchType"
        :params-data="paramsData"
        :table-data="tableData"
        :settlement-date="settlementDate"
        :REWARD_STATUS="UPDATE_REWARD_STATUS"
        :REMARKS="REMARKS"
        :statusData="statusData"
        @toExcel="toExcel"
        @updateRow="updateRow"
        @bankInputRequest="bankInputRequest"
        @sendAlarmTalk="sendAlarmTalk"
      />
    </template>
  </search-page-layout>
</template>
<script>
import NewPaymentSearchBar from "@/pages/Settlement/components/NewPaymentManage/NewPaymentSearchBar";
import NewPaymentTable from "@/pages/Settlement/components/NewPaymentManage/NewPaymentTable";
import TableMixin from "@/mixin/TableMixin";
import CodeMixin from "@/mixin/CodeMixin";
import {axiosInstance} from "@/util/AxiosModule";
import _ from "lodash";
import func from "@/util/func";
import SearchPageLayout from "@/layouts/SearchPageLayout";

export default {
  mixins: [TableMixin, CodeMixin],
  components: {SearchPageLayout, NewPaymentTable, NewPaymentSearchBar},
  async created() {
    this.REWARD_STATUS = await this.getSelectList("PAYMENT_REWARD_STATUS");
    this.UPDATE_REWARD_STATUS = await this.getSelectList("PAYMENT_UPDATE_REWARD_STATUS");
    this.REMARKS = await this.getSelectList("PAYMENT_REMARKS");
  },
  data: () => ({
    rawData: [],
    tableData: [],
    backupData: [],
    loading: false,
    searching: false,
    onlySettlementDate: false,
    settlementDate: "",
    REWARD_STATUS: [],
    UPDATE_REWARD_STATUS: [],
    REMARKS: [],
    paramsData: [],
    statusData: "",
    showButton: false,
    duppSnoLength: 0,
    searchType: "",
    isFilter: false
  }),
  watch : {
    onlySettlementDate: function(value) {
      this.loading = true;
      if(value) {
        this.backupData = this.tableData;
        this.tableData = this.tableData.filter((x) => (x[`settlementDate`] === this.settlementDate));
      } else {
        this.tableData = this.backupData.length < 1 ? this.rawData : this.backupData;
        this.backupData = [];
      }
      this.loading = false;
    }
  },
  methods: {
    // 상태 변경 감지
    changeStatus(status) {
      this.statusData = status;
    },
    setSettlementDate(value) {
      this.settlementDate = value;
    },
    async search(params) {
      this.duppSnoLength = 0;
      this.loading = true;
      this.paramsData = params;

      try {
        const {data} = await axiosInstance.get("/admin/paymentList", { params });

        // 객체마다 인덱스 부여
        let idx = 0;
        for (const item of data) {
          item["index"] = idx++;
          if (item["remark"] === "DUPP_SNO") {
            this.duppSnoLength += 1;
          }
        }
        if (this.duppSnoLength > 0) {
          alert(`${this.duppSnoLength}개의 중복건이 존재합니다`);
        }
        // searchType이 "지급액"일 경우
        if (this.searchType === "A") {
          this.showButton = true;
        }

        this.settlementDate = params.settlementDate;
        this.rawData = data;
        // 목록 데이터
        // this.tableData = data;
        this.tableData = data.filter(item => !(item.sumReward === 0 && item.rewardStatusCd === "SUCCESS"));
        // 조회 시 실행
        this.searching = true;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    // filter(상태, 회원명, 주민등록번호)
    filter(status, nm, ssno) {
      this.loading = true;

      // 상태가 전체이고 회원명,주민번호 없을때
      if (status === "ALL" && !nm && !ssno) {
        // 전체 데이터를 필터
        // this.tableData = this.rawData;
        this.tableData = this.rawData.filter(item => !(item.sumReward === 0 && item.rewardStatusCd === "SUCCESS"));
        this.showButton = false;
        // 나머지일 경우
      } else if(status === "SUCCESS") {
        this.showButton = true;
        this.tableData = this.rawData.filter((x) => (this.filterFunc(x, status, nm, ssno)));
        this.tableData = this.tableData.filter(item => !(item.sumReward === 0 && item.rewardStatusCd === "SUCCESS"));
      } else {
        this.showButton = false;
        this.tableData = this.rawData.filter((x) => (this.filterFunc(x, status, nm, ssno)));
      }
      this.loading = false;
    },
    // 필터 함수(x, 상태, 회원명, 주민등록번호)
    filterFunc(x, status, nm, ssno) {
      // 상태가 전체가 아닐경우
      if (status !== "ALL") {
        // 회원명이 있고 주민번호가 없을경우
        if (nm && !ssno) {
          return x["rewardStatusCd"] === status && x["recipientNm"] === nm;
          // 회원명이 없고 주민번호가 있을경우
        } else if (!nm && ssno) {
          return x["rewardStatusCd"] === status && x["ssno"] === ssno;
          // 회원명, 주민번호 둘다 있는 경우
        } else if (nm && ssno) {
          return x["rewardStatusCd"] === status && x["recipientNm"] === nm && x["ssno"] === ssno;
          // 나머지
        } else {
          return x["rewardStatusCd"] === status
        }
        // 상태가 전체일 경우
      } else {
        // 회원명이 있고 주민번호가 없을경우
        if (nm && !ssno) {
          return x["recipientNm"] === nm;
          // 회원명이 없고 주민번호가 있을경우
        } else if (!nm && ssno) {
          return x["rewardStatusCd"] === status && x["ssno"] === ssno;
          // 회원명, 주민번호 둘다 있는 경우
        } else if (nm && ssno) {
          return x["rewardStatusCd"] === status && x["recipientNm"] === nm && x["ssno"] === ssno;
          // 나머지
        } else {
          return true;
        }
      }
    },
    toExcel() {
      if (_.isEmpty(this.tableData)) return;
      const data = _.cloneDeep(this.tableData);
      const returnData = [];

      let excelStatus = this.statusData;
      if (excelStatus === "") {
        excelStatus = "전체";
      } else if (excelStatus === "TARGET") {
        excelStatus = "지급예정";
      } else if (excelStatus === "SUCCESS") {
        excelStatus = "지급완료";
      } else if (excelStatus === "FAIL") {
        excelStatus = "지급불가";
      }

      for (const item of data) {
        let payDateNm = "";
        if (this.searchType === "A") {
          payDateNm = "지급완료일";
        } else {
          payDateNm = "지급예정일";
        }

        const obj = {
          "기준연월": item["settlementDate"],
          "회원명": item["recipientNm"],
          "회원전화번호": item["telNum"],
          "지급이력": item["hasSuccess"] ? "기존" : "신규",
          [payDateNm]: item["payDate"],
          "조기지급건수": Number(item["ecnt"]),
          "환수대상건수": Number(item["hcnt"]),
          "월정산건수": Number(item["mcnt"]),
          "지급건수": Number(item["scnt"]),
          "조기지급액": Number(item["erewardAmt"]),
          "환수대상액": Number(item["hrewardAmt"]),
          "월정산액": Number(item["mrewardAmt"]),
          "지급액": Number(item["srewardAmt"]),
          "주민등록번호": item["ssno"],
          "은행명": item["bankNm"],
          "계좌번호": item["bankAccount"],
          "상태": this.searchByCodeInSelectList(this.REWARD_STATUS, item["rewardStatusCd"]),
          "비고": this.searchByCodeInSelectList(this.REMARKS, item["remark"])
        }
        returnData.push(obj);
      }

      let searchTypeNm = "";
      switch (this.paramsData.searchType) {
        case "A":
          searchTypeNm = "지급액";
          break;
        case "B":
          searchTypeNm = "미지급액";
          break;
        case "C":
          searchTypeNm = "렌카";
          break;
        default:
          searchTypeNm = "";
          break;
      }

      const sheetName = `${this.settlementDate}`;
      const fileName = `지급처리(신)-(${excelStatus})(${this.settlementDate})-${searchTypeNm}`;
      func.extractExcelFile(returnData, sheetName, fileName);
    },
    async updateRow(selectData, status, remark) {
      this.loading = true;

      try {
        await axiosInstance.put("/admin/paymentList", selectData, {
          params: {
            rewardStatus: status,
            remark: remark
          }
        });

        for (const item of selectData) {
          const raw = _.find(this.rawData, {
            recipientId: item["recipientId"]
          });

          const table = _.find(this.tableData, {
            recipientId: item["recipientId"]
          });

          const value = this.searchByCodeInSelectList(this.REWARD_STATUS, status);
          raw["rewardStatusCd"] = status;
          raw["rewardStatusValue"] = value;
          table["rewardStatusCd"] = status;
          table["rewardStatusValue"] = value;

          raw["remark"] = table["remark"] = _.isEmpty(remark) ? "" : remark;
        }

        alert("상태가 업데이트 되었습니다!");
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    async bankInputRequest(selectData) {
      this.loading = true;

      try {
        await axiosInstance.post("/admin/payment/accountProc", selectData);
        alert("알림톡이 전송되었습니다!");
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },
    async chgOnlySettlementDate(value) {
      this.onlySettlementDate = value;
    },
    initData() {
      this.searching = false;
      this.settlementDate = "";
      this.rawData = [];
      this.tableData = [];
      this.statusData = "";
      this.showButton = false;
    },
    async sendAlarmTalk(selectData) {
      this.loading = true;
      try {
        await axiosInstance.post('/admin/payment/sendList', selectData);
        alert(`${selectData.length}개의 알림톡이 전송되었습니다.`);
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    getSearchType(type) {
      this.searchType = type;
    }
  }
}
</script>